<template>
  <div class="alarm-deal">
    <Common-Shell type="alarmDeal" :search-option="searchOption"></Common-Shell>
  </div>
</template>

<script>
import CommonShell from "../comps/CommonShell.vue";

export default {
  name: "alarmDeal",
  components: { CommonShell },
  props: {},
  data() {
    return {};
  },
  computed: {
    searchOption() {
      return {};
    },
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.alarm-deal {
  height: 100%;
}
</style>
